@font-face {
  font-family: 'Biennale';
  font-weight: bold;
  src: local('Biennale'), url(./fonts/BiennaleBold/LatinotypeBiennaleBold.otf) format('truetype');
}

@font-face {
  font-family: 'OpenSauceSansLight';
  font-weight: normal;
  src: local('OpenSauceSansLight'), url(./fonts/OpenSauceSans/OpenSauceSans-Light.ttf) format('truetype');
}

body {
  margin: 0;
  /* overflow-y: hidden; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

.kt-divider {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-right: 32px;
  margin-left: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kt-divider > span:first-child {
  width: 100%;
  height: 1px;
  flex: 1 1;
  background: #ebecf1;
  display: inline-block;
}

.kt-divider > span:not(:first-child):not(:last-child) {
  padding: 0 2rem;
}

.kt-divider > span:last-child {
  width: 100%;
  height: 1px;
  flex: 1 1;
  background: #ebecf1;
  display: inline-block;
}
.sticky {
  position: absolute;
  right: -30px;
  top: 320px;
  z-index: 150;
  transform: rotate(7deg);
  width: 200px;
  min-height: 150px;
  margin: -10px 10px 10px;
  padding: 10px;
  font-size: 14px;
  color: #000;
  background: rgba(255, 255, 51, 0.9);
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.3);
}
.sticky:before,
.sticky:after {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  right: 0;
}
.sticky:before {
  border-top: solid 8px #fff;
  border-right: solid 8px #fff;
  border-left: solid 8px transparent;
  border-bottom: solid 8px transparent;
}
.sticky:after {
  border-bottom: solid 8px #dddd33;
  border-left: solid 8px #dddd33;
  border-right: solid 8px transparent;
  border-top: solid 8px transparent;
}

.sticky2 {
  width: 200px;
  min-height: 150px;
  margin: 10px 10px 10px;
  padding: 10px;
  font-size: 14px;
  color: #000;
  position: relative;
  background: rgba(255, 255, 51, 0.9);

  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.3);
}
.sticky2:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  right: 0;
}
.sticky2:before {
  border-top: solid 8px #fff;
  border-right: solid 8px #fff;
  border-left: solid 8px transparent;
  border-bottom: solid 8px transparent;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #fff #eee;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 20px;
}

.dashboard-chart-area {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-chart-area .contentFont {
  width: 100%;
}
.dashboard-chart-area .no-data {
  font-size: 13px;
  color: #bbbbbb;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.dashboard-chart-title-area {
  height: 65px;
  display: flex;
  align-items: flex-end;
}
.dashboard-chart-title-area h4 {
  color: rgba(0, 0, 0, 0.87);
  padding-right: 20px;
}

button {
  outline: 0 !important;
}

.compareChartTooltip {
  color: rgba(0, 0, 0, 0.87);
}
.compareChartTooltip .compareRow {
  color: #909090;
}
.compareChartTooltip .gain {
  color: green;
}
.compareChartTooltip .lose {
  color: red;
}

.ps__rail-x {
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: unset;
  top: 0px;
}

.ps > .ps__rail-x,
.ps > .ps__rail-y {
  opacity: 0.6;
}