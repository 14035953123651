body,
h3,
.pdfButton span,
.contentFont,
.menuFont {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.menuFont {
  font-size: 1rem;
}

h4,
code,
.tableHeader th,
.titleFont,
.sticky,
.sticky2 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

h4,
.tableHeader th,
.titleFont {
  font-weight: bold;
}
